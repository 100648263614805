html{
  font-size: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--gray-dark-900, #F9FAFB);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  margin: 0;
}

.section{
  display: block;
  padding: 6.4rem 1.6rem;

  color: var(--gray-dark-900, #F9FAFB);

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.dm-shade-1{
  background-color: #030712;
}

.dm-shade-2{
  background: var(--gray-dark-50, #111827);
}

.centered{
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-bar {
  height: 6.8rem;

  padding: 1.6rem;
  margin: 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;
} 

.logo{
  color: var(--gray-dark-900, #F9FAFB);
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 120% */
  letter-spacing: -0.6px; 
}

.menu-button{
  border: none;
  background-color: #030712;

  width: 3rem;
  height: 3rem;

  cursor:pointer;
}

.menu-button img{
  height: 3rem;
  width: 3rem;

  color: #F9FAFB;
}

.hide {
  display: none;
}

.wide {
  display: none;
}

.menu {
  display: flex;
  list-style: none;
  flex-direction: column;
  justify-content: space-between;

  padding: 1.6rem;
  margin: 0px;
  height: auto;

  font-size: 1.6rem;

  border-top: 1px #1F2937 solid;
  border-bottom: 1px #1F2937 solid;
}

.menu a{
  color: #D1D5DB;
  text-decoration: none;

  font-family: Inter;
  font-style: normal;
}

.links{
  margin-bottom: 1rem  ;
}

.download{
  padding: 1.6rem;
  border-bottom: 1px #1F2937 solid;
}

.download a{
  text-decoration: none;
  border: none;
  border-radius: 2rem;

  margin: 0px auto 0px auto;
  padding: .5rem .5rem;

  color: #111827;
  background-color: #F9FAFB;

  width: 100%;
}


.intro p{
  color: var(--gray-dark-600, #D1D5DB);

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
}

.headshot{
  margin: 0px 0px 4.8rem 0px;
  z-index: 1;
}

.headshot img{
  border: .75rem #030712 solid;

  display: block;
  margin: 0px auto;
  width: 60%;
  height: auto;

  box-shadow: 
  1.5rem 1.5rem 0rem #374151,
  -1.5rem 1.5rem 0rem #374151
}

.location{
  margin: 4.8rem 0px;
  display: flex;

  height: 5.4rem;
  flex-direction: column;
  justify-content: space-between;

}

.container{
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;

  width: 2.4rem;
  height: 2.4rem;
}

.box {
  display: flex;
  align-items: center;
}

.box img{
  width: 2rem;
  height: 2rem;

  margin: 0;
}

.green-dot{
  width: .8rem;
  height: .8rem;
  flex-shrink: 0;

  border-radius: 20px;
  background: var(--emerald-500, #10B981);
}

.socails{
  width: 6rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.socails img{
  cursor:pointer;
}

.small {
  width: 2.4rem;
  height: 2.4rem;
}

.medium {
  width: 3.6rem;
  height: 3.6rem;
}

.about-me h3{
  align-self: stretch;

  color: var(--gray-dark-900, #F9FAFB);

  font-family: Inter;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  letter-spacing: -.048rem;
}

.bio p{
  align-self: stretch;

  color: var(--gray-dark-600, #D1D5DB);

  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;

  margin-bottom: 1.6rem;
}


.section-title{
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1rem;
  font-weight: 100;

  width: fit-content;
  height: 1rem;

  padding: .4rem 2.0rem;
  margin: 0px auto 2.4rem auto;

  border-radius: 12px;
  background: var(--gray-dark-200, #374151);
}

.fullbody {
  display: block;

  width: 18rem;
  height: 32rem;

  margin: 0px auto 6.8rem auto;
  border: .75rem #111827 solid;

  box-shadow: 
  1.5rem 1.5rem 0rem #374151,
  -1.5rem 1.5rem 0rem #374151
}

#final-say{
  margin: 0;
}

.reach-out{
  display: flex;
  justify-content: center;

  color: var(--gray-dark-600, #D1D5DB);
  text-align: center;

  font-family: Inter;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;
}
 
.contact-info{
  padding: 0 2rem;

  color: var(--gray-dark-900, #F9FAFB);
  text-align: center;
  
  width: fit-content;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.36px;
}

.copy-paste{
  cursor: pointer;
}

.spacer{
  margin: 2.8rem 0;
}

.together{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .header{
    padding: 1.6rem 8rem;
  }

  .menu-button{
    display: none;
  }

  .wide{
    display: flex;
    flex-direction: row;
  }

  .nav-bar{
    padding: 0 3.2rem;
    height: 3.6rem;

  }

  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
  }

  .menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    

    padding: 1.6rem;
    margin: 0px;
    height: auto;

    font-size: 1.6rem;

    border-top: 0px;
    border-bottom: 0px;
    border-right: 1px #1F2937 solid;
  }

  .menu li{
    margin-left: 1rem;
  }

  .links{
    margin: 0;
  }

  .download {
    border: none;
    padding: 0;
    margin-left: 1rem;
  }

  .download a{
    padding: .6rem 1.6rem;
  }

  .section{
    padding: 9.6rem 8rem;
  }

  .intro{
    display: flex;
    flex-direction: row-reverse;
  }

  .buffer{
    padding: 0 3.2rem;
  }

  .headshot img{
    height: 32rem;
    width: auto;

    margin: 0 7.2rem 0 4.8rem;

    box-shadow:   
    4rem 4rem 0rem #374151
  }

  .flex{
    display: flex;
  }

  .about-me img{
    height: 40rem;
    width: auto;

    margin: 0 7.2rem 0 4.8rem;

    box-shadow:   
    -4rem 4rem 0rem #374151
  }

  .about-me h3{
    margin: 0 0 2.4rem 0;
  }

  /* .space-around{
    display: flex;
    justify-content: space-between;
  } */

}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { }

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { }